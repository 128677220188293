import React, { useState, useEffect, useContext } from "react";
import { Context } from "./store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop";
import { BackendURL } from "./component/backendURL";
import { Redirect, useNavigate } from "react-router-dom";
import axios from "axios";

import { Login } from "./pages/Login";
import injectContext from "./store/appContext";

import { Sashatech } from "./pages/sashatech";
import { jwtDecode } from "jwt-decode";
import { Layout_pista } from "./Rol/Activacion_pista/layout_pista";
import { Navbar } from "./component/navbar";
import { Footer } from "./component/footer";
import { RegistroUsuarios } from "./pages/RegistroUsuarios";
import { NewRegister } from "./pages/NewRegister";
import { Contactanos } from "./pages/Contactanos";
import { BlogNoticias } from "./pages/BlogNoticias";
import { SobreNosotros } from "./pages/SobreNostros";
import { Layout_dir_terminal } from "./Rol/Dir/Layout_dir_terminal";
import LoadingOverlay from "./pages/LadingOverlay";
import { BlogDetalle } from "./pages/BlogDetalle";
import { HelmetProvider } from "react-helmet-async";

const Layout = () => {
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const basename = process.env.BASENAME || "";

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const token = localStorage.getItem("token");
  let userRole = null;
  if (token) {
    const decoded = jwtDecode(token);
    userRole = decoded.rol; // Aquí obtienes el rol del token
  }

  const renderLayout = () => {
    if (isLoading) {
      return <div>{isLoading && <LoadingOverlay />}</div>;
    }
    switch (userRole) {
      case "GENERAL":
        return <Layout_dir_terminal toggleLoading={toggleLoading} />;
      default:
        return (
          <div>
            <HelmetProvider>
              <BrowserRouter basename={basename}>
                <ScrollToTop>
                  <Navbar />
                  <Routes>
                    <Route element={<Login />} path="/Login" />
                    <Route element={<BlogNoticias />} path="/Blog" />
                    <Route element={<SobreNosotros />} path="/SobreNosotros" />
                    <Route element={<BlogDetalle />} path="/blog/detalle/:id" />
                    {/* <Route element={<NewRegister />} path="/Registro" /> */}
                    <Route element={<Contactanos />} path="/contactanos" />
                    <Route element={<Sashatech />} path="/"></Route>
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                  <Footer />
                </ScrollToTop>
              </BrowserRouter>
            </HelmetProvider>
          </div>
        );
    }
  };
  return renderLayout();
};

export default injectContext(Layout);
